import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import VueSweetalert2 from 'vue-sweetalert2';
import BootstrapVue from 'bootstrap-vue';
import vSelect from 'vue-select';
import ToggleButton from 'vue-js-toggle-button'
import Multiselect from 'vue-multiselect';
import VModal from 'vue-js-modal';
import * as Vuetable from 'vuetable-2';

import 'vue-loading-overlay/dist/vue-loading.css';

import Loading from 'vue-loading-overlay';

import VueLodash from 'vue-lodash'
const options = { name: 'lodash' };

import { localize } from 'vee-validate';
import es from 'vee-validate/dist/locale/es.json';

localize('es', es);

Vue.component('multiselect', Multiselect);

// Plugins 
import Axios from './plugins/AxiosConfig';

import { ConfirmComponent, RowComponent, Checkbox2Component, InputError } from '@/layouts/components';
import {
    DateFormat,
    HourFormat,
    WeekDays
} from '@/filters';

import VueCurrencyFilter from 'vue-currency-filter'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// Filters
Vue.filter('date_format', DateFormat);
Vue.filter('hour_format', HourFormat);
Vue.filter('week_days', WeekDays);

// Component
Vue.component('ConfirmComponent', ConfirmComponent);
Vue.component('RowComponent', RowComponent)
Vue.component('vuetable-field-checkbox2', Checkbox2Component);
Vue.component('input-error', InputError);
Vue.component('v-select', vSelect)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(ToggleButton)
Vue.use(VModal, {
    dynamicDefaults: {
        draggable: false,
        height: 'auto'
    }
});
Vue.use(Axios);
Vue.use(VueLodash, options);
Vue.use(VueCurrencyFilter, {
    symbol: '$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
});

Vue.component('loading', Loading)
    // Composition API
Vue.use(VueCompositionAPI)
Vue.use(Vuetable);

Vue.use(VueSweetalert2);
Vue.use(BootstrapVue);

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')