export default {
    namespaced: true,
    state: {
        year: null,
        month: null,
        client: null,
        pageDashboard: false
    },
    getters: {
        getYear: state => state.year,
        getMonth: state => state.month,
        getClient: state => state.client,
        getPageDashboard: state => state.pageDashboard,
    },
    mutations: {
        setYear(state, year) {
            state.year = year
        },
        setMonth(state, month) {
            state.month = month
        },
        setClient(state, client) {
            state.client = client
        },
        setPageDashboard(state, dashboard) {
            state.pageDashboard = dashboard
        },
    },
    actions: {},
}