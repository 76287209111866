<template>
  <div>
    <p>
      <feather-icon v-if="display== 'none'" @click="active" icon="ChevronsDownIcon"/>
      <feather-icon v-if="display== 'block'" @click="inactive" icon="ChevronsUpIcon"/>
      <span class="ml-2" @click="activeInactive">{{title}}</span>
    </p>

    <div :style="contentStyle">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "CollapseComponent",
  data() {
    return {
      display: "none"
    };
  },
  props: {
    title: String
  },
  methods: {
    active() {
      this.display = "block";
    },
    inactive() {
      this.display = "none";
    },
    activeInactive() {
      this.display == "none" ? "block" : "none";
    }
  },
  computed: {
    contentStyle: function() {
      return {
        display: this.display
      };
    }
  }
};
</script>

<style>
</style>