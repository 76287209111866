<template>
  <th v-if="isHeader" class="vuetable-th-component-checkbox">
    <input
      type="checkbox"
      @change="toggleAllCheckbox($event)"
      :checked="isAllItemsInCurrentPageSelected()"
    />
  </th>
  <td v-else class="vuetable-td-component-checkbox">
    <input
      type="checkbox"
      @change="toggleCheckbox(rowData, $event)"
      :checked="isSelected(rowData)"
      :disabled="isDisabled(rowData)"
    />
  </td>
</template>
<script>
import VuetableFieldCheckboxMixin from "vuetable-2/src/components/VuetableFieldCheckboxMixin";

export default {
  name: "vuetable-field-checkbox2",
  mixins: [VuetableFieldCheckboxMixin],
  methods: {
    toggleCheckbox(dataItem, event) {
      this.vuetable.onCheckboxToggled(
        event.target.checked,
        {
          name: this.rowField.name,
          item: dataItem
        },
        dataItem
      );
    },
    isSelected(rowData) {
      return this.vuetable.isSelectedRow(rowData[this.vuetable.trackBy]);
    },
    toggleAllCheckbox(event) {
      const isChecked = event.target.checked;
      let idColumn = this.vuetable.trackBy;
      if (isChecked) {
        this.vuetable.tableData
          .filter(dataItem => !this.isDisabled(dataItem))
          .forEach(dataItem => {
            this.vuetable.selectId(dataItem[idColumn]);
          });
      } else {
        this.vuetable.tableData.forEach(dataItem => {
          this.vuetable.unselectId(dataItem[idColumn]);
        });
      }
      this.vuetable.fireEvent("checkbox-toggled-all", isChecked);
    },
    isDisabled(rowData) {
      if (this.rowField.isDisabled) {
        return this.rowField.isDisabled(rowData);
      }
      return false;
    }
  }
};
</script>
