<script>
import { Line, mixins } from "vue-chartjs";

export default {
  extends: Line,
  name: "bar-component",
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: () => {
        return {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                },
              }
            ]
          }
        };
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>
