import axios from 'axios';

export default class UserService {

    /**
     * @description login
     * @param { username | login, password } values
     */
    static index() {
        return axios.post(process.env.VUE_APP_API + '/users');
    }

    /**
     * @description store data
     * @param data { location_id, street, height,  postal_code } values
     */
    static store(data) {
        return axios.post(process.env.VUE_APP_API + '/users', data);
    }

    /**
     * @description delete a element
     * @param { id } id point
     */
    static destroy({ id }) {
        return axios.delete(process.env.VUE_APP_API + '/users/' + id);
    }

    /**
     * @description update a element
     * @param { id } id point
     * @param values { location_id, street, height,  postal_code } update values
     */
    static update(id, values) {
        return axios.put(process.env.VUE_APP_API + '/users/' + id, values);
    }

    /**
     * @description get a element by id
     * @param { id } id point
     */
    static show(id) {
        return axios.get(process.env.VUE_APP_API + '/users/' + id);
    }

    /**
     * @description get a element by id
     * @param
     */
    static newPassword(data) {
        return axios.post(process.env.VUE_APP_API + '/user/new-password/', data);
    }

    /*
     * @description login
     * @param { username | email, password } values
     */
    static login(values) {
        const instance = axios.create();
        return instance.post(process.env.VUE_APP_API + '/login', values);
    }

    /* @description register
     * @param { username | email, password } values
     */
    static register(values) {
        return axios.post(process.env.VUE_APP_API + '/signin', values);
    }

    /**
     * @description logout : send to login page
     */
    static async logout() {
        return axios.delete(process.env.VUE_APP_API + '/logout');

    }

    /**
     * @description logout : send to login page
     */
    static me() {
        return axios.get(process.env.VUE_APP_API + '/user/me');
    }

    /**
     * @description logout : send to login page
     */
    static notifications() {
        return axios.get(process.env.VUE_APP_API + '/user/me/notifications');
    }

    /**
     * @description logout : send to login page
     */
    static storeNotifications(data) {
        return axios.post(process.env.VUE_APP_API + '/user/store-notification', data);
    }

    /**
     * @description logout : send to login page
     */
    static showNotifications(id) {
        return axios.get(process.env.VUE_APP_API + '/user/store-notification/' + id);
    }


    static getNotification(id) {
        return axios.get(process.env.VUE_APP_API + '/user/me/notification/' + id);
    }


    /**
     * @description logout : send to login page
     */
    static historyNotifications(page) {
        const params = { page }
        return axios.get(process.env.VUE_APP_API + '/user/me/notifications/history', { params });
    }

    /**
     * @description
     */
    static saveMe(data) {
        const body = {
            brand: data.brand,
            type_document: data.type_document,
            dni: data.dni,
            name: data.name,
            last_name: data.last_name,
            phone: data.phone,
            direction: {
                location_id: data.direction.location_id,
                province_id: data.direction.province_id,
                postal_code: data.direction.postal_code,
                height: data.direction.height,
                street: data.direction.street,
                note: data.direction.note,
            },
            collection: data.collection,
            collection_start_time: data.collection_start_time,
            collection_end_time: data.collection_end_time,
            retirement: data.retirement,
            notes: data.notes,
            point_id: data.point.id,
            vat_condition: data.vat_condition,
            voucher_type: data.voucher_type,
            is_secured: data.is_secured,
        };
        return axios.post(process.env.VUE_APP_API + '/user/me', body);
    }

    /**
     * 
     */
    static saveShipping(data) {
        return axios.post(process.env.VUE_APP_API + '/user/me/shipping', data);
    }

    /**
     * @description logout : send to login page
     */
    static async readAllNotifications() {
        return axios.get(process.env.VUE_APP_API + '/user/read-notifications');
    }


    /**
     * @description logout : send to login page
     */
    static forgot_password(values) {
        return axios.post(process.env.VUE_APP_API + '/reset-password', values);
    }

    /**
     * @description logout : send to login page
     */
    static reset_password(values) {
        return axios.post(process.env.VUE_APP_API + '/reset/password', values);
    }

    /**
     * @description store data
     * @param data {  } data
     */
    static save(data, id) {
        return id ? this.update(id, data) : this.store(data);
    }

    /**
     * @description logout : send to login page
     */
    static user() {
        return JSON.parse(localStorage.getItem('user'));
    }

    /**
     * @description logout : send to login page
     */
    static token() {
        return localStorage.getItem('token');
    }

    /**
     * @description  time
     */
    static setTime(time) {
        return localStorage.setItem('time', time);
    }

    /**
     * @description  time
     */
    static time() {
        return localStorage.getItem('time');
    }

    /**
     * @description user
     */
    static setUser(user) {
        return localStorage.setItem('user', JSON.stringify(user));
    }

    /**
     * @description logout : send to login page
     */
    static setUserNotification(notifications) {
        let user = localStorage.getItem('user');
        user.notifications = notifications
        localStorage.setItem('user', JSON.stringify(user));
    }

    /**
     * @description logout : send to login page
     */
    static setToken(token) {
        return localStorage.setItem('token', token);
    }

    /**
     * @description logout : send to login page
     */
    static isAdmin() {
        const user = this.user();
        return user && user.roles && user.roles.find(d => d.name === 'admin') != null;
    }

    /**
     * @description logout : send to login page
     */
    static isCustomer() {
        const user = this.user();
        return user && user.roles && user.roles.find(d => d.name === 'customer') != null;
    }


    /**
     * @description logout : send to login page
     */
    static isStaff() {
        const user = this.user();
        return user && user.roles && user.roles.find(d => d.name === 'staff') != null;
    }


    /**
     * @description 
     */
    static isReseller() {
        const user = this.user();
        return user && user.roles && user.roles.find(d => d.name === 'reseller') != null;
    }

    /**
     * @description logout : send to login page
     */
    static isAdminOrStaff() {
        return this.isAdmin() || this.isStaff();
    }

    /**
     * @description logout : send to login page
     */
    static isAdminOrStaffOrReseller() {
        return this.isAdmin() || this.isStaff() || this.isReseller();
    }

    /**
     * 
     */
    static updateLabelProduct(label_products) {
        return axios.post(process.env.VUE_APP_API + '/user/update-products', { label_products });
    }
}