<template>
  <div class="message-box dialog-mask" @keydown.esc="$close(false)">
    <div class="dialog-content">
      <header >{{ title }}</header>
      <div class="dialog-body">
        <p>{{ content }}</p>
      </div>
      <div class="pull-right">
          <b-button class="mx-1 text-white" :variant="yes.variant" size="sm" @click="$close(true)">{{yes.title}}</b-button>
          <b-button class="mx-1 text-white" :variant="no.variant" size="sm"  @click="$close(false)">{{no.title}}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmComponent",
  props: {
    title: String,
    content: String,
    yes: Object,
    no: Object
  }
};
</script>

<style lang="scss" scoped>
.dialog-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.33);
}

.dialog-content {
  margin: 0 10px;
  padding: 10px 10px;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  color: #303133;

  header {
    margin: 10px 0 10px;
    font-weight: bold;
  }

  p, .el-button {
    font-size: inherit;
  }

  p {
    white-space: pre-wrap;
  }

  .el-button {
    flex: 1;
    border: 0;
    color: #409EFF;
    appearance: none;
  }

  footer {
    display: flex;
  }
}

.message-box .dialog-content {
  min-width: 400px;
  text-align: center;
  font-size: 16px;
}

</style>