<template>
  <b-form-invalid-feedback>
    <p>
      <span v-bind:key="error.id" v-for="error in errors">{{ error }}</span>
    </p>
  </b-form-invalid-feedback>
</template>

<script>
export default {
  name: "input-error",
  props: ["errors"],
};
</script>

<style>
</style>