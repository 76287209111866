<template>
  <div class="filter-bar float-right mt-1" style="width: 100%; display: flex">
    <slot></slot>
    <b-form-input
      class="mr-1"
      type="search"
      v-model="filterText"
      @input="onSearch"
      placeholder="Buscar"
      size="sm"
    ></b-form-input>
    <button class="btn btn-sm btn-primary" @click="doFilter">
      <feather-icon icon="SearchIcon" />
    </button>
  </div>
</template>

<script>
export default {
  name: "filter-bar-component",
  data() {
    return {
      filterText: "",
    };
  },
  methods: {
    doFilter() {
      this.$emit("search", this.filterText);
    },
    onSearch() {
      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut);
      }
      this.searchTimeOut = setTimeout(() => {
        this.$emit("search", this.filterText);
      }, 500);
    },
  },
};
</script>
