import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import dashboard from './dashboard'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    operations: [],
    customer: Object
  },
  mutations: {
    user(state, user) {
      state.user = user
    },
    change(state, operations) {
      state.operations = operations
    },
    changeCustomer(state, customer) {
      state.customer = customer;
      state.operations = []
    },

    changeExtra(state, operations) {
      state.operations = operations
    },

  },
  getters: {
    operations: state => state.operations,
    user: state => state.user
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    dashboard
  },
  strict: process.env.DEV,
})
