
import moment from "moment";

export function DateFormat(value, format = 'DD/MM/YYYY HH:mm A') {
    //,"America/Argentina/Buenos_Aires"
    const m = moment(value);
    if( m.isValid()){
        return m.format(format);
    }
    return 'invalid date';
}
