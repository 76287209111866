
import moment from "moment";

export function HourFormat(value, format = 'h:mm A') {
    //,"America/Argentina/Buenos_Aires"
    const m = moment(value, 'HH:mm');
    if (m.isValid()) {
        return m.format(format);
    }
    return 'invalid date';
}
