import Swal from "sweetalert2";

export default class AlertService {

  static get confirmButtonColor() {
    return "#f68d1e";
  }

  static get cancelButtonColor() {
    return "#adb5bd";
  }

  static get customClass() {
    let customClass = {
      confirmButton: 'mx-1 px-1 swal2-button-change',
      denyButton: 'mx-1 swal2-button-change',
      cancelButton: 'mx-1 px-1 swal2-button-change',
    }
    return customClass;
  }

  /**
   * @description
   */
  static async delete(title, text, options = {}) {
    options = Object.assign({
      type: "warning",
      title,
      text,
      showCancelButton: true,
      confirmButtonColor: AlertService.confirmButtonColor,
      cancelButtonColor: AlertService.cancelButtonColor,
      confirmButtonText: "Si",
      cancelButtonText: "Volver",
      reverseButtons: true,
      customClass: AlertService.customClass
    }, options)
    return await Swal.fire(options);
  }

  static async question(title, text, options = {}) {
    options = Object.assign({
      type: "warning",
      title,
      text,
      showCancelButton: true,
      confirmButtonColor: AlertService.confirmButtonColor,
      cancelButtonColor: AlertService.cancelButtonColor,
      confirmButtonText: options.habilitarCustomer ? options.habilitarCustomer : "Si",
      cancelButtonText: "Volver",
      reverseButtons: true,
      customClass: AlertService.customClass
    }, options)
    return await Swal.fire(options);
  }

  static async questionText(title, text, options = {}) {
    options = Object.assign({
      type: "warning",
      input: 'text',
      title,
      text,
      showCancelButton: true,
      confirmButtonColor: AlertService.confirmButtonColor,
      cancelButtonColor: AlertService.cancelButtonColor,
      confirmButtonText: options.deshabilitarCustomer ? options.deshabilitarCustomer : "Si",
      cancelButtonText: "Volver",
      reverseButtons: true,
      customClass: AlertService.customClass
    }, options)
    return await Swal.fire(options);
  }

  static async info() {
    return await Swal.fire({
      title: "Operación exitosa",
      confirmButtonColor: AlertService.confirmButtonColor,
      customClass: AlertService.customClass
    });
  }

  static async error(title, text) {
    if (!Swal.isVisible()){
      return await Swal.fire({
        title: title,
        text: text,
        confirmButtonColor: AlertService.confirmButtonColor,
        type: "error",
      });
    }
  }

}
