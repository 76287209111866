import moment from "moment";
import axios from "axios";
// import * as _ from "lodash";

const options = [
  {
    detallecontrol: "VISITADO NO ENTREGAD",
    correct_detallecontrol: "Visitado no entregado",
    correct_subtitle_detallecontrol: 'Te visitamos y no te encontramos! Vamos a pasar nuevamente en 24/48hs para poder entregarte tu envío.'
  }
];

export default class UtilService {

  /**
   * get discount in human fomar
   * @param data
   */
  static date(value, format = 'DD/MM/YYYY HH:mm A') {
    const m = moment(value);
    if (m.isValid()) {
      return m.format(format);
    }
    return 'invalid date';
  }

  /**
   * get discount in human fomar
   * @param data
   */
  static dateCreatedAt(value, format = 'DD/MM/YYYY HH:mm A') {
    const m = moment(value);
    if (m.isValid()) {
      return m.format(format);
    }
    return 'invalid date';
  }


  /**
   * get discount in human fomar
   * @param data  client | point | client & point
   */
  static serviceRetirement(data) {
    if (data === "client") {
      return "Domicilio";
    }
    if (data === "point") {
      return "Punto de retiro";
    }
    if (data === "client & point") {
      return "Domicilio y punto de retiro";
    }
    return "N/D";
  }

  /**
   * get discount in human fomar
   * @param data  client | deposit
   */
  static serviceCollection(data) {
    if (data) {
      return data === "client" ? "Cliente" : "Drop-Off";
    }
    return "N/D";

  }

  /**
   * get discount in human fomar
   * @param data  client | deposit
   */
  static statusUser(data) {
    if (data) {
      return data === "enabled" ? "ACTIVO" : "INACTIVO";
    }
    return "N/D";
  }

  
  /**
   * get discount in human format
   * @param { discount } price
   */
  static loadData(apiUrl, httpOptions) {
    return axios.get(process.env.VUE_APP_API + apiUrl, httpOptions);
  }


  static detalleControlDeonicsStr(option) {
    let detallecontrol = null;
    if (option != [] && option != null && option != "") {
      if (option.detallecontrol != null) {
        detallecontrol = option.detallecontrol.trim().toUpperCase();
      }
    }
    if (detallecontrol != null && detallecontrol != "") {
      const element = options.find(d => d.detallecontrol === detallecontrol);
      return element ? element.correct_subtitle_detallecontrol : detallecontrol;
    } else {
      return null;
    }
  }


}



