<template>
  <div>
    <div
      class="table-responsive loading"
      ref="loading"
      style="min-height: 500px"
    >
      <div class="loading-info">
        <h4>Loading...</h4>
      </div>
      <vuetable
        ref="vuetable"
        :api-url="url"
        :http-fetch="loadData"
        :fields="fields"
        :append-params="searchParameter"
        :css="table"
        :per-page="perPage"
        :noDataTemplate="'No hay datos para mostrar.'"
        pagination-path="meta"
        @vuetable:loading="showLoader"
        @vuetable:pagination-data="onPaginationData"
        @vuetable:load-success="onLoadSuccess"
        @vuetable:checkbox-toggled="selectOne"
        @vuetable:checkbox-toggled-all="selectMany"
        @vuetable:loaded="hideLoader"
        @vuetable:load-error="errorLoadData"
      >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </vuetable>
    </div>

    <div style="height: 25px; margin-top: 5px">
      <!-- <select
        class="form-control form-control-sm"
        v-model="perPage"
        @input="onPagination($event)"
        style="width: 60px; float: left"
      >
        <option :value="10">10</option>
        <option :value="20">20</option>
        <option :value="50">50</option>
        <option :value="100">100</option>
      </select> -->
      <vuetable-pagination-info
        style="
          width: 270px;
          padding-left: 5px;
          padding-top: 6px;
          font-size: 12px;
          float: left;
          margin-left: 15px;
        "
        ref="paginationInfo"
        info-template="{from} de {to}.  total {total} elementos"
      ></vuetable-pagination-info>

      <vuetable-pagination
        class="pagination pr-1 pb-1"
        ref="pagination"
        :css="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import UtilService from "../../services/UtilService";

export default {
  name: "full-table-component",
  components: {},
  data() {
    return {
      params: {},
      searchParameter: {},
      selectTo: [],
      pagination: {
        infoClass: " ",
        wrapperClass: "pagination float-right",
        activeClass: "active",
        disabledClass: "disabled",
        pageClass: "page-item page-link",
        linkClass: "page-item page-link",
        icons: {
          first: "",
          prev: "",
          next: "",
          last: "",
        },
      },
      table: {
        tableClass: "table table-sm",
        tableHeaderClass: "thead-dark table-header",
      },
    };
  },
  methods: {
    /**
     *
     */
    search(search, params = {}, clearPage = false) {
      this.searchParameter.search = search;
      this.params = Object.assign(this.params, params);
      if (clearPage) {
        this.$refs.vuetable.changePage(1);
      }
      this.$refs.vuetable.reload();
    },

    /**
     * @description Update elements in the page
     */
    updatePage(elements) {
      if (this.$refs.vuetable && this.$refs.vuetable.tableData) {
        this.$refs.vuetable.tableData.forEach((item) => {
          const _item = elements.find((i) => i.id === item.id);
          if (_item) {
            Object.assign(item, _item);
          }
        });
      }
      //this.$refs.vuetable.
    },

    /**
     * @description clear parameters
     */
    clear() {
      this.selectTo = [];
      this.searchParameter = {};
      this.params = {};
      this.$refs.vuetable.changePage(1);
      this.$refs.vuetable.reload();
    },

    /**
     * @description
     */
    refresh() {
      this.selectTo = [];
      this.$refs.vuetable.reload();
    },

    /**
     * @description
     */
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },

    /**
     * @description
     */
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },

    onLoadSuccess(response) {
      this.$emit("onLoadSuccess", response);
    },
    /**
     * @description
     */
    onPagination(event) {
      this.perPage = parseInt(event.target.value);
    },

    /**
     *
     */
    onLoading(event) {
      console.log("event loading", event);
    },
    /**
     * @description
     */
    loadData(apiUrl, httpOptions) {
      httpOptions.params = Object.assign(httpOptions.params, this.params);
      return UtilService.loadData(apiUrl, httpOptions);
    },
    /**
     * @description
     */
    formatUserStatus(value) {
      return UtilService.statusUser(value);
    },
    /**
     * @description
     */
    selectOne(select, { item }) {
      if (select) {
        this.selectTo.push(item);
      } else {
        const index = this.selectTo.findIndex((d) => d.id === item.id);
        if (index >= 0) {
          this.selectTo.splice(index, 1);
        }
      }
      this.$emit("selectTo", this.selectTo);
    },
    /**
     * @description
     */
    selectMany(select) {
      if (select) {
        this.getDataToSelect().forEach((item) => {
          const index = this.selectTo.findIndex((d) => d.id === item.id);
          if (index < 0) {
            this.selectTo.push(item);
          }
        });
      } else {
        const elements = this.$refs.vuetable.tableData;
        elements.forEach((item) => {
          const index = this.selectTo.findIndex((d) => d.id === item.id);
          if (index >= 0) {
            this.selectTo.splice(index, 1);
          }
        });
      }
      this.$emit("selectTo", this.selectTo);
    },
    /**
     * @description
     */
    getDataToSelect() {
      const filter = this.fields.find((d) => d.name === "__checkbox2");
      if (filter && filter.isDisabled) {
        return this.$refs.vuetable.tableData.filter((dataItem) => {
          return !filter.isDisabled(dataItem);
        });
      }
      return this.$refs.vuetable.tableData;
    },

    /**
     * @loading event show
     */
    showLoader() {
      this.$refs.loading.classList.add("loading");
    },
    /**
     * @loading event show
     */
    hideLoader() {
      this.$refs.loading.classList.remove("loading");
    },
    /**
     * @loading event show
     */
    errorLoadData() {
      this.$refs.loading.classList.remove("loading");
    },
  },
  computed: {},
  props: {
    url: String,
    fields: Array,
    perPage: {
      type: Number,
      default: 10,
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  text-align: center;
  border: 1px solid #efefef;
  color: gray;
  .table-header {
    background: #3ba8d8;
    color: white;
  }
  .custom-actions {
    button {
      background-color: DodgerBlue;
      border: none;
      color: white;
      padding: 4px;
      font-size: 5px;
      cursor: pointer;
    }
  }
}

.foot-table-pagiantion {
  height: 40px;
  margin-top: 20px;
}

.loading {
  // max-height: 350px;
  background: white;
  opacity: 0.45;
  z-index: 101;
  .loading-info {
    display: unset;
  }
  .table {
    display: none;
  }
}

.loading-info {
  display: none;
}

.table.table-sm th,
.table.table-sm td {
  padding: 0.75rem 0.5rem !important;
}
</style>
