import Vue from 'vue'
import VueRouter from 'vue-router'
import UserService from '../services/UserService'
import store from '../store/index'

// // Containers
// const DefaultContainer = () => import('@/containers/DefaultContainer');
const DefaultLanding = () => import('@/containers/DefaultLanding');

// // Views
const Dashboard = () => import('@/views/pages/dashboard/Dashboard');

// // Points
const PointAll = () => import('@/views/pages/points/PointAll');
const PointUpload = () => import('@/views/pages/points/PointUpload')
const PriceAll = () => import('@/views/pages/prices/PriceAll');
const UserAll = () => import('@/views/pages/users/UserAll');
const ServiceAll = () => import('@/views/pages/services/ServiceAll');
const CustomerAll = () => import('@/views/pages/customers/CustomerAll');
const BalancePage = () => import('@/views/pages/balances/BalancePage');

// // Operations 
const OperationAll = () => import('@/views/pages/operations/OperationAll');
const OperationAdd = () => import('@/views/pages/operations/OperationAdd');
const OperationEdit = () => import('@/views/pages/operations/OperationEdit');
const OperationDuplicate = () => import('@/views/pages/operations/OperationDuplicate');
const OperationPay = () => import('@/views/pages/operations/OperationPay');
const OperationReturnChange = () => import('@/views/pages/operations/OperationReturnChange');

// //* Users
const Profile = () => import('@/views/pages/profile/Profile');

// // Login
const Login = () => import('@/views/Login');
const WShipment = () => import('@/views/WShipment');
const EmailVerified = () => import('@/views/pages/EmailVerified');
const Register = () => import('@/views/pages/authentication/Register.vue');
const ResetPassword = () => import('@/views/pages/ResetPassword');
const ForgotPassword = () => import('@/views/pages/authentication/ForgotPassword.vue');

// Setting
const Setting = () => import('@/views/pages/settings/Setting');
const SettingExchangeAndRefund = () => import('@/views/pages/settings/SettingExchangeAndRefund');
const SettingPercentageDeclared = () => import('@/views/pages/settings/SettingPercentageDeclared.vue');
const SettingPostalCode = () => import('@/views/pages/settings/SettingPostalCode');

// // notifications
const HistoryNotification = () => import('@/views/pages/notifications/HistoryNotification');
const BillNotification = () => import('@/views/pages/notifications/BillNotification');
const StoreNotification = () => import('@/views/pages/notifications/StoreNotification');

// // location province 
const LocationProvinceAll = () => import('@/views/pages/locations/LocationProvinceAll.vue');
const LocationUpload = () => import('@/views/pages/locations/LocationUpload');

Vue.use(VueRouter)

const removeLoading = () => {
  setTimeout(() => {
      const elements = document.getElementsByClassName('loading-modal');
      while (elements.length > 0) {
          elements[0].remove();
      }
  }, 1000)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      path: '/',
      redirect: 'login',
      name: 'defaultLanding',
      component: DefaultLanding,
      meta: {
        layout: 'full',
      },
      children: [{
          path: '/login',
          name: 'Login',
          component: Login,
          meta: {
            layout: 'full',
          },
          beforeEnter: (to, from, next) => {
            removeLoading();
            next();
          },
        },
        {
          path: '/register',
          name: 'auth-register',
          component: Register,
          meta: {
            layout: 'full',
          },
          beforeEnter: (to, from, next) => {
            removeLoading();
            next();
          },
        },
        {
          path: '/reset-password',
          name: 'auth-forgot-password',
          component: ForgotPassword,
          meta: {
            layout: 'full',
          },
        },
        {
          path: '/where_is_my_shipment',
          name: 'Where_is_my_shipment',
          component: WShipment,
          meta: {
            layout: 'full',
          },
          beforeEnter: (to, from, next) => {
              removeLoading();
              next();
          },
        },
        {
          path: '/email_verified',
          name: 'EmailVerified',
          component: EmailVerified,
          meta: {
            layout: 'full',
          },
          beforeEnter: (to, from, next) => {
              removeLoading();
              next();
          },
        },
        {
          path: '/reset-password/:token',
          name: 'reset-password-form',
          component: ResetPassword,
          meta: {
            layout: 'full',
          },
          beforeEnter: (to, from, next) => {
              removeLoading();
              next();
          },
        }
      ]
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('user') !== 'null') {
          UserService.me().then((response) => {
            UserService.setUser(response.data.data);
            store.commit('user', response.data.data);
            removeLoading();
            next();
          }).catch((error) => {
            console.error(error);
            removeLoading();
            UserService.setToken(null);
            UserService.setUser(null);
            UserService.setTime(null);
            next('/login');
          });
        } else {
          removeLoading();
          next('/login');
        }
      },
    },
    {
      path: '/dashboard/settings/users',
      name: 'usuarios',
      component: UserAll,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/settings/state',
      name: 'state',
      component: Setting,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/settings/profile',
      name: 'profile',
      component: Profile,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/settings/exchange-and-refund',
      name: 'settingExchangeAndRefund',
      component: SettingExchangeAndRefund,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/settings/percentage-declared',
      name: 'settingPercentageDeclared',
      component: SettingPercentageDeclared,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/settings/postal-code',
      name: 'SettingPostalCode',
      component: SettingPostalCode,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/services',
      name: 'services',
      component: ServiceAll,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/locations',
      name: 'locations',
      component: LocationProvinceAll,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/locations/upload',
      name: 'locations upload',
      component: LocationUpload,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/locations/prices',
      name: 'prices',
      component: PriceAll,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/points',
      name: 'points',
      component: PointAll,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/points/upload',
      name: 'upload points',
      component: PointUpload,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/customers',
      name: 'customers',
      component: CustomerAll,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/balances',
      name: 'balances',
      component: BalancePage,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/operations',
      name: 'operations',
      component: OperationAll,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/operations/add',
      name: 'addOperation',
      component: OperationAdd,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/operations/:id/return-change',
      name: 'changeOperation',
      component: OperationReturnChange,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/operations/duplicate/:id',
      name: 'duplicateOperation',
      component: OperationDuplicate,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/operations/edit/:id',
      name: 'editOperation',
      component: OperationEdit,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/operations/pay/:id',
      name: 'payOperation',
      component: OperationPay,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/notifications',
      name: 'notification',
      component: HistoryNotification,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/notifications/bill-error/:id',
      name: 'billErrorNotification',
      component: BillNotification,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard/store-notification',
      name: 'storeNotification',
      component: StoreNotification,
      meta: {
        requiresAuth: true
      },
    },
    // {
    //   path: '/error-404',
    //   name: 'error-404',
    //   component: () => import('@/views/error/Error404.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '*',
      redirect: '/dashboard/',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('user') === null) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router