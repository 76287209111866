<template>
  <div>
    <p :class="pClass">
      <b>{{ title }}</b>
      <template v-if="badge">
        <b-badge variant="primary" class="ml-2" style="font-size: 12px">
          <span v-if="type === 'amount'"> {{ content | currency }}</span>
          <span v-else> {{ content }}</span>
        </b-badge>
      </template>
      <template v-else>
        <span v-if="type === 'amount'"> {{ content | currency }}</span>
        <span v-else> {{ content }}</span>
      </template>
    </p>
  </div>
</template>

<script>
export default {
  name: "RowComponent",
  props: {
    title: String,
    content: [String, Number],
    type: {
      type: String,
      default: "text",
    },
    pClass: String,
    badge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 12px;
  margin: 5px;
  padding-bottom: 4px;
  .title {
    text-align: right;
    width: 20%;
  }
}
</style>
